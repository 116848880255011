<template>
  <div class="page">
    <Viewheader :isHome="false" ref="navheader" />
    <!-- banner图 -->
    <BannerHead :bannerImg="bannerImg" :title="bigTitle_1" :desc="smallTitle_1">
      <div class="header-custom" slot="content">
        <img class="phone" src="@/assets/img/chatgpts/phone.png" />
        <img class="seo" src="@/assets/img/chatgpts/seo.png" />
        <img class="optimize" src="@/assets/img/chatgpts/optimize.png" />
        <img class="drainage" src="@/assets/img/chatgpts/drainage.png" />
        <img class="common" src="@/assets/img/chatgpts/common.png" />
      </div>
    </BannerHead>
    <!-- 核心功能 -->
    <div class="core-function">
      <CommonTitle title="核心功能" />
      <ColumuTwo
        class="core-function_main"
        :list="spanList"
        :img="coreImg"
      ></ColumuTwo>
    </div>

    <!-- 能力展示 -->
    <div class="similar-main-wrap">
      <div class="similar-main">
        <CommonTitle title="能力展示" />
        <div class="chatClass">
          <!-- 左侧菜单 -->
          <div class="chatLeft">
            <div class="chatRight_menu">
              <el-row class="tac">
                <el-col :span="24">
                  <el-menu
                    default-active="0"
                    class="el-menu-vertical-demo"
                    @open="handleOpen"
                    @close="handleClose"
                    :unique-opened="true"
                  >
                    <el-submenu index="1">
                      <template slot="title">
                        <span>SEO站外引流</span>
                      </template>
                      <el-menu-item-group
                        v-for="(item, index) in data.seo_stations"
                        :key="index"
                      >
                        <el-menu-item
                          @click="checkChat(item)"
                          :index="`${item.id}`"
                          >{{ item.classification }}</el-menu-item
                        >
                      </el-menu-item-group>
                    </el-submenu>

                    <el-submenu index="2">
                      <template slot="title">
                        <span>社媒站外引流</span>
                      </template>
                      <el-menu-item-group
                        v-for="(item1, index1) in data.media_stations"
                        :key="index1"
                      >
                        <el-menu-item
                          :index="`${item1.id}`"
                          @click="checkChat(item1)"
                          >{{ item1.classification }}</el-menu-item
                        >
                      </el-menu-item-group>
                    </el-submenu>

                    <el-submenu index="3">
                      <template slot="title">
                        <span>站内营销</span>
                      </template>
                      <el-menu-item-group
                        v-for="(item2, index2) in data.site_marketing"
                        :key="index2"
                      >
                        <el-menu-item
                          :index="`${item2.id}`"
                          @click="checkChat(item2)"
                          >{{ item2.classification }}</el-menu-item
                        >
                      </el-menu-item-group>
                    </el-submenu>

                    <el-submenu index="4">
                      <template slot="title">
                        <span>商品信息优化</span>
                      </template>
                      <el-menu-item-group
                        v-for="(item3, index3) in data.product_info"
                        :key="index3"
                      >
                        <el-menu-item
                          :index="`${item3.id}`"
                          @click="checkChat(item3)"
                          >{{ item3.classification }}</el-menu-item
                        >
                      </el-menu-item-group>
                    </el-submenu>

                    <el-submenu index="5">
                      <template slot="title">
                        <span>Excel处理</span>
                      </template>
                      <el-menu-item-group
                        v-for="(item4, index4) in data.excel"
                        :key="index4"
                      >
                        <el-menu-item
                          :index="`${item4.id}`"
                          @click="checkChat(item4)"
                          >{{ item4.classification }}</el-menu-item
                        >
                      </el-menu-item-group>
                    </el-submenu>

                    <el-submenu index="6">
                      <template slot="title">
                        <span>通用场景跨境</span>
                      </template>
                      <el-menu-item-group
                        v-for="(item5, index5) in data.universal_cross_border"
                        :key="index5"
                      >
                        <el-menu-item
                          :index="`${item5.id}`"
                          @click="checkChat(item5)"
                          >{{ item5.classification }}</el-menu-item
                        >
                      </el-menu-item-group>
                    </el-submenu>

                    <el-submenu index="7">
                      <template slot="title">
                        <span>python+java代码处理</span>
                      </template>
                      <el-menu-item-group
                        v-for="(item6, index6) in data.code_processing"
                        :key="index6"
                      >
                        <el-menu-item
                          :index="`${item6.id}`"
                          @click="checkChat(item6)"
                          >{{ item6.classification }}</el-menu-item
                        >
                      </el-menu-item-group>
                    </el-submenu>
                  </el-menu>
                </el-col>
              </el-row>
            </div>
          </div>

          <!-- 右侧结果 -->
          <!-- :class="{ frosted_glasses: isActive }" -->
          <div class="chatRight">
            <!-- <p>相似文案推荐：</p> -->
            <div v-if="showGpt" class="showGpt" ref="scrollWrapper">
              <div v-for="(i, index) in gptQueslist">
                <div class="chatRight_gpts_right">
                  <div class="problemSpan">
                    <span class="dateTime">{{ i.date }}</span>
                    {{ i.problem }}
                  </div>
                  <div class="ball"></div>
                </div>
                <!-- 答案 -->
                <div class="chatRight_gpts">
                  <div class="ball_1">
                    <img src="../../assets/img/chatFlower.png" />
                  </div>
                  <div class="answer_span">
                    <div class="loading" style="display: none">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                    <span class="dateTimeAns">{{ i.date }}</span>
                    <span>
                      <span
                        v-highlight
                        v-html="i.answer"
                        class="answerGpt"
                      ></span>
                    </span>
                  </div>
                </div>
              </div>
              <div
                class="StopResponding"
                v-if="StopResponding"
                @click="stopRes()"
              >
                <img src="../../assets/img/stop.png" /> Stop Responding
              </div>
              <!-- 动态追加 -->
              <!-- <div class="chatRight_gpts" v-if="final">
                        <div class="ball_1"><img src="../../assets/img/chatFlower.png" ></div>
                        <div class="answer_span">
                        <div class="loading" v-if="this.loading">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
  
                          <span>
                            <span  class="answerGpt"></span>
                          </span>
                        </div>
                </div> -->
            </div>
            <div class="chatRight_gpt2" v-if="showDefault">
              <span>Feitian-LLM跨境场景应用，助力运营提效。</span>
              <span
                >预置Feitian-LLM在跨境电商各场景应用的prompt指令，更好的利用Feitian-LLM解决业务问题。</span
              >
            </div>

            <div class="chatRight_gpt">
              <el-form :model="formUrl" class="formData_chat">
                <el-form-item label="" class="formData_item">
                  <el-input
                    placeholder=" "
                    v-model="formUrl.text"
                    type="textarea"
                    resize="none"
                    class="chatInput"
                  ></el-input>
                </el-form-item>
                <el-button
                  type="primary"
                  class="subBtn_chat"
                  @click="submitForm('formUrl')"
                  :disabled="this.sendisabled"
                  >发送</el-button
                >
              </el-form>
            </div>
          </div>
          <div class="msg-tips" id="msgTip" style="display: none">
            文本解析中...
          </div>
        </div>
      </div>
    </div>

    <!-- 应用场景 -->
    <div class="application-scene">
      <CommonTitle title="应用场景" />
      <ColumuTwo
        class="application-scene_main"
        :list="appliList"
        :img="applicationImg"
      ></ColumuTwo>
    </div>

    <!-- 尾部 -->
    <Footering />
  </div>
</template>

<script>
// banner
import BannerHead from './components/Banner.vue';
import CommonTitle from './components/CommonTitle.vue';
import ColumuTwo from './components/ColumuTwo.vue';
// import BannerHead from "@/components/centerPage/banner_head.vue";

import bg from '@/assets/img/chatgpts/bg.png';
import coreImg from '@/assets/img/chatgpts/core.png';
import applicationImg from '@/assets/img/chatgpts/application.png';
import cha2 from '@/assets/img/children/cha2.png';
import app2 from '@/assets/img/gongneng/gpt/changjing.png';

// 公用组件
import '../../assets/css/public.css';
import '../../assets/css/normalize.css';
import '../../assets/css/commonModule.css';
// 头部
import Viewheader from '../../components/viewheader/viewheader.vue';
// foote组件
import Footering from '../../components/foot/footer.vue';
import axios from 'axios';
import { getChatLabel } from '@/api/aidata.js';

export default {
  components: {
    BannerHead,
    CommonTitle,
    ColumuTwo,
    Viewheader,
    Footering,
  },
  data() {
    return {
      bannerImg: bg,
      coreImg: coreImg,
      applicationImg: applicationImg,
      bigTitle_1: 'Feitian跨境业务应用',
      smallTitle_1:
        '预置Feitian-LLM在跨境电商各场景应用的prompt指令，更好的利用Feitian-LLM解决业务问题',

      // 核心功能
      spanList: [
        {
          title: '预置跨境常见场景prompt',
          desc: '预置了24个场景应用的prompt，并会持续的更新。',
        },
        {
          title: '支持多版本模型替换',
          desc: '我们支持多个版本的LLM的切换，接口调用更稳定并可进行业务下游任务的深度定制。',
        },
      ],

      //   差异优势
      diffImg: cha2,
      diffList: [
        {
          diffFirst: '暂无文案',
          diffSecond:
            '暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案',
        },
        {
          diffFirst: '暂无文案',
          diffSecond:
            '暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案暂无文案',
        },
      ],

      //   应用场景
      appImg: app2,
      appliList: [
        {
          title: '商品信息质量优化',
          desc: '优化站内商品信息质量，上架前通过标题等基础信息生成、优化商品短描、卖点、详描，生成商品的FAQ、补充商品属性信息。',
        },
        {
          title: '站外引流素材生成',
          desc: '通过Feitian-LLM在seo场景生成引流博客、优化商品标题及TDK商品页面描述，生成引流视频中商品介绍；社媒引流中，titok标题、#标签词、post广告语等生成。',
        },
        {
          title: '站内营销场景',
          desc: '通过商品已有的标题、属性等信息生成商品卖点描述、个性化大促会场营销语、推荐理由等；书写商品推广营销邮件、客户挽回邮件等。',
        },
      ],
      formUrl: {
        text: '',
      },
      data: {},
      id: '',
      quesConfig: '',
      answerConfig: '',
      showDefault: true,
      showGpt: true,
      txtList: [],
      isActive: false,
      loading: false,
      sendisabled: false,
      // resultlist:[],
      gptQueslist: [],
      dateTime: '',
      // 暂停按钮⏸
      StopResponding: false,
      source: '',
      userManual: false, //判断用户是否手动取消
    };
  },
  mounted() {
    this.getChatList();
    // 在组件挂载后将滚动条设置为最底部
    this.scrollToEnd();
    this.gptQueslist = JSON.parse(localStorage.getItem('gptQueslistValue'))
      ? JSON.parse(localStorage.getItem('gptQueslistValue'))
      : [];

    // 判断是否有缓存数据
    if (this.gptQueslist.length == 0) {
      this.showDefault = true;
      this.showGpt = false;
    } else {
      this.showDefault = false;
      this.showGpt = true;
    }
  },
  updated() {
    // 在组件更新后将滚动条设置为最底部
    this.scrollToEnd();
  },
  methods: {
    // chatgpt数据展示
    getChatList() {
      getChatLabel().then(({ data }) => {
        if (data.state === '0x0000') {
          this.data = data.data;
        } else if (data.state === '0x0008') {
          this.$message({
            message: data.message,
            type: 'error',
          });
          this.$refs.navheader.logOn();
        } else {
          this.$message({
            message: data.message,
            type: 'error',
          });
        }
      });
    },
    // 回车发送
    handleKeydown(event) {
      console.log(event.shiftKey);
      console.log(event.keyCode);
      if (!event.shiftKey && event.keyCode == 13) {
        if (!this.sendisabled) {
          // console.log("123")
          //取消回车默认换行
          event.returnValue = false;
          this.submitForm();
        }
      }
    },
    // 获取chartGPT应用发送查询
    submitForm() {
      // this.$message({
      //   message: '功能尚未开放',
      //   type: 'info',
      // });
      // return;
      if (this.formUrl.text === '') {
        this.$message({
          message: '请输入内容',
          type: 'error',
        });
        return;
      } else {
        this.showDefault = false;
        this.showGpt = true;
      }

      this.sendisabled = true;
      this.StopResponding = true;
      // 获取最后一个标签
      this.loading = true;
      // this.quesConfig = this.formUrl.text;
      this.date();
      // 存储历史记录 问题、答案
      this.gptQueslist.push({
        problem: this.formUrl.text,
        date: this.dateTime,
      });

      let data = {
        prompt: this.formUrl.text,
        // projectName: "对外平台-技术中心-调大模型接口",
        // stream: true,
      };

      // 用户取消
      const source = axios.CancelToken.source();
      let serverTime = 0;
      axios({
        method: 'post',
        // url: 'https://chatgpt-api.dhgate.com/api/v3/chat/completions',
        url: '/api/text/chatGPTSse',
        data: data,
        timeout: 3 * 60 * 1000,
        headers: {
          'Content-Type': 'application/json',
          token: window.localStorage.getItem('tokenKey') || '',
          // 'Authorization':'308516066bee496db55f55f197036952'
        },
        //取消请求
        cancelToken: source.token,
        onDownloadProgress: ({ event }) => {
          const index = this.gptQueslist.length - 1;
          const answer = this.gptQueslist[index].answer || '';
          if (answer) {
            this.loading = false;
          }
          const xhr = event.target;
          const { responseText } = xhr;

          const { text, state, timestamp, end } = this.arrangeText(
            responseText,
            serverTime,
          );
          serverTime = timestamp;

          // const lastIndex = responseText.lastIndexOf(
          //   'data:',
          //   responseText.length - 2,
          // );
          // let chunk = responseText;
          try {
            // if (lastIndex !== -1) {
            //   chunk = responseText
            //     .substring(lastIndex)
            //     .substring('data:'.length);
            //   // console.log('responseText', chunk);
            //   chunk = chunk ? JSON.parse(chunk) : {};
            // }
            if (state === '0x0000') {
              // let content =
              //   chunk.data && chunk.data.content ? chunk.data.content : '';
              const steamContent = answer + text;

              this.$set(this.gptQueslist[index], 'answer', steamContent);
              // console.log(this.gptQueslist[index]);
              localStorage.setItem(
                'gptQueslistValue',
                JSON.stringify(this.gptQueslist),
              );

              this.scrollToEnd();

              if (end) {
                this.sendisabled = false;
                this.StopResponding = false;
              }
              // if(data.detail.choices[0].finish_reason == 'stop'){
              //   this.sendisabled = false
              // }
            } else if (state === '0x0001') {
              this.$message({
                message: '模型输出过长',
                type: 'warning',
                customClass: 'custom-el-message',
                // duration: 0,
                iconClass: '',
                offset: 115,
              });
              this.StopResponding = false;
            }
          } catch (error) {}
        },
      })
        .then(({ data }) => {
          console.log(data, Date.now());
          if (data.state) {
            this.StopResponding = false;
            this.sendisabled = false;
            this.loading = false;
          }

          if (data.state == '0x0008') {
            this.$message({
              type: 'error',
              message: data.message,
              duration: 1000,
              onClose: () => {
                this.$refs.navheader.logOn();
              },
            });
          }
          if (data.state == '0x0010') {
            this.$message({
              type: 'error',
              message: data.message,
              duration: 1000,
            });
            this.$set(
              this.gptQueslist[this.gptQueslist.length - 1],
              'answer',
              data.message,
            );
          }
        })
        .catch((error) => {
          this.sendisabled = false;
          this.StopResponding = false;
          this.loading = false;
          $('div[class=loading]:last').hide();

          // 判断用户是否手动取消
          if (this.userManual) {
            console.log('请求被用户取消');
            this.userManual = false;
          } else {
            // 用户超时取消
          }
        });
      this.source = source;
      this.formUrl.text = '';
    },
    // 处理收到的数据 按时间戳
    arrangeText(responseText, timestamp = 0) {
      let texts = responseText.split('data:');
      let list = texts.filter((item) => item).map((item) => JSON.parse(item));
      const index = list.findIndex((item) => {
        return item.serverTime == timestamp;
      });
      if (index != -1) {
        list = list.slice(index + 1);
      }
      let end = false;
      let state = '';
      const text = list.reduce((prev, cur) => {
        end = cur?.data?.end || false;
        state = cur.state;
        if (cur.state !== '0x0000') return '';
        return prev + (cur?.data?.content || '');
      }, '');

      timestamp = list[list.length - 1]?.serverTime || 0;
      return { timestamp, text, end, state };
    },
    // 点击终端请求
    stopRes() {
      if (this.source) {
        this.source.cancel('用户手动取消');
        // 判断用户是否手动取消
        this.userManual = true;
      }
    },
    // 获取当前时间
    date() {
      var date = new Date();
      var year = date.getFullYear();
      var month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      var hours =
        date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
      var minutes =
        date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
      var seconds =
        date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
      this.dateTime =
        year +
        '/' +
        month +
        '/' +
        day +
        '  ' +
        hours +
        ':' +
        minutes +
        ':' +
        seconds;
    },
    // 将滚动条滚动到最底部
    scrollToEnd() {
      const scrollWrapper = this.$refs.scrollWrapper;
      if (scrollWrapper != undefined) {
        scrollWrapper.scrollTop = scrollWrapper.scrollHeight;
      }
    },
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    // 选中时做的操作
    checkChat(item) {
      if (!this.showGpt) {
        this.showDefault = true;
        this.showGpt = false;
      }
      this.id = item.id;
      this.formUrl.text = item.quesConfig.replace(/<br\/>/g, '\n');
    },
  },
};
</script>
<style scoped lang="less">
.hljs {
  background-color: #fff;
  color: #333333;
}
.chatClass {
  width: 100%;
  height: 720px;
  background: #fff;
  border-radius: 16px;
  opacity: 1;
  display: flex;
  align-content: flex-start;
  padding: 24px;
  margin: 40px auto 0;
  position: relative;
  box-sizing: border-box;
}
.chatRight {
  /* height: 600px; */
  /* background-color: #fff; */
  /* overflow-y: scroll; */
  padding: 12px 0 0 25px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.chatRight > p {
  font-size: 16px;
  color: #000;
}
.showGpt {
  height: 587px;
  background-color: #fff;
  overflow-y: scroll;
  padding-bottom: 50px;
  padding-top: 10px;
  /* position: relative; */
}
/*滚动条样式*/
.showGpt::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.showGpt::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2); /*设置滚动条颜色*/
}
.showGpt::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  border-radius: 0;
  /* background:rgba(0,0,0,0.1); */
}
.chatRight_gpt {
  width: 100%;
  height: 168px;
  background: #f0f2f5;
  border-radius: 6px 6px 6px 6px;
  padding: 15px 15px 23px 15px;
  margin-bottom: 15px;
  margin-top: 15px;
  box-sizing: border-box;
}

.chatRight_gpt2 {
  width: 95%;
  height: 587px;
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.chatRight_gpt2 span {
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}

.chatRight_gpts {
  /* width: 757px; */
  /* height: 255px; */
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  padding: 0 0 48px;
  /* margin-bottom: 15px; */
  display: flex;
}
.chatRight_gpts_right {
  /* width: 757px; */
  /* height: 255px; */
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  padding: 0 0 48px;
  /* margin-bottom: 15px; */
  display: flex;
  justify-content: right;
}
.chatRight_gpts_right:first-child {
  padding-top: 24px;
}

.answer_span {
  /* width: 466px; */
  max-width: 80%;
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #494b5a;
  margin-left: 12px;
  /* overflow-y: overlay; */
  white-space: pre-wrap;
  word-break: break-all;
  background-color: #f0f0f0;
  padding: 16px;
  border-radius: 8px;
  position: relative;
  /* margin-top: 10px; */
}
/*滚动条样式*/
.answer_span::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.answer_span::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2); /*设置滚动条颜色*/
}
.answer_span::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  border-radius: 0;
  /* background:rgba(0,0,0,0.1); */
}
.problemSpan {
  max-width: 80%;
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #494b5a;
  /* margin-left: 200px; */
  margin-right: 12px;
  white-space: pre-wrap;
  background: rgba(108, 56, 224, 0.1);
  border-radius: 8px;
  padding: 16px;
  word-break: break-all;
  /* overflow-y: overlay; */
  position: relative;
}
.dateTime {
  position: absolute;
  right: 0;
  top: -24px;
  font-size: 14px;
  color: #9e9e9e;
  width: fit-content;
  white-space: nowrap;
}
.dateTimeAns {
  position: absolute;
  left: 0;
  top: -24px;
  font-size: 14px;
  color: #9e9e9e;
  width: fit-content;
  white-space: nowrap;
}
.chatRight_gpt .el-button {
  position: absolute;
  right: 16px;
  bottom: 16px;
  width: 96px;
  height: 42px;
  background: #6c38e0;
  font-size: 16px;
  border-radius: 4px;
}
.chatRight_gpt .el-button:hover {
  background: #6c38e0 !important;
}
/*滚动条样式*/
.problemSpan::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.problemSpan::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2); /*设置滚动条颜色*/
}
.problemSpan::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  border-radius: 0;
  /* background:rgba(0,0,0,0.1); */
}
.chatRight_gpt:last-child {
  margin-bottom: 0;
  height: 150px;
  padding: 10px 15px 0px 14px;
  position: relative;
}

.chatRight_menu {
  width: 265px;
  height: 100%;
  /* margin-right: 23px; */
  /* margin-left: 38px; */
  overflow-x: hidden;
  overflow-y: revert;
}
.chatRight_menu .el-menu-vertical-demo {
  width: 265px;
}
/*滚动条样式*/
.chatRight_menu::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.chatRight_menu::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2); /*设置滚动条颜色*/
}
.chatRight_menu::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  border-radius: 0;
  /* background:rgba(0,0,0,0.1); */
}
.ball {
  width: 48px !important;
  height: 48px !important;
  background: #6c38e0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: PingFang-SC-Regular, PingFang-SC;
  font-weight: 400;
  color: #fff;
  line-height: 14px;
  /* margin-left: 20px; */
  /* margin: 0px; */
}

.ball_1 img {
  width: 48px;
  height: 48px;
}
/deep/.el-textarea__inner {
  width: calc(100% - 100px);
  height: 107px;
  border: none;
  background: transparent;
}

/*滚动条样式*/
.chatInput .el-textarea__inner::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 4px;
}
.chatInput .el-textarea__inner::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 5px;
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2); /*设置滚动条颜色*/
}
.chatInput .el-textarea__inner::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  -webkit-box-shadow: inset005pxrgba(0, 0, 0, 0.2);
  border-radius: 0;
  /* background:rgba(0,0,0,0.1); */
}

.formData_item {
  margin-bottom: 10px;
}

.subBtn_chat {
  /* float: right; */
  width: 122px;
  height: 46px;
  background: #6567e1 linear-gradient(180deg, #361ecd 0%, #777aed 0%);
  border-radius: 6px 6px 6px 6px;
  border: none;
  position: absolute;
  right: 2px;
  bottom: 2px;
}

.subBtn_chat:hover {
  background: #6567e1 linear-gradient(180deg, #361ecd 0%, #777aed 0%);
}

.el-menu {
  border-right: none;
  background: none;
  width: 265px;
  font-size: 16px;
  font-family: PingFang-SC-Bold, PingFang-SC;
  font-weight: bold;
  color: #000108;
  line-height: 16px;
}

.el-icon-arrow-down:before {
  /* content: "\e6e0"; */
  content: '' !important;
  display: block;
  background-image: url(../../assets/img/chatgpts/arrow.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 14px;
  width: 8px;
}

.chatLeft {
  padding-right: 24px;
  position: relative;
  flex-shrink: 0;
}
.chatLeft::after {
  content: '';
  position: absolute;
  width: 2px;
  height: calc(100% - 12px);
  bottom: 0;
  right: 0;
  background-color: rgba(240, 242, 245, 1);
}
.chatLeft .el-submenu__icon-arrow {
  right: 0;
  transform-origin: left center;
}
.chatLeft .el-submenu.is-opened > .el-submenu__title .el-submenu__icon-arrow {
  transform: rotateZ(90deg);
}
.chatLeft .el-menu-item.is-active {
  background-color: rgba(0, 1, 8, 0.05);
}

.chatLeft /deep/.el-submenu__title {
  /* height: 30px;
  line-height: 30px;
  padding-left: 0px !important; */
  font-size: 16px;
  padding: 0 !important;
  height: 46px;
  line-height: 46px;
  font-weight: 600;
  &:hover {
    background: transparent;
  }
}
.chatLeft .el-menu-item,
.el-submenu__title {
  height: 46px;
  line-height: 46px;
}

.el-submenu:last-child .el-submenu__title {
  /* width: 200px; */
}

.el-submenu {
  /* margin-bottom: 20px; */
}

.chatLeft .el-submenu__title:hover {
  background-color: transparent;
}

.el-menu-item.is-active {
  color: #6567e1;
  line-height: 22px;
  width: 185px;
  height: 22px;
  background: #edebf3;
  border-radius: 4px 4px 4px 4px;
}

.chatLeft .el-submenu .el-menu-item {
  font-size: 14px;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  /* margin-bottom: 12px; */
  padding: 0 0 0 16px !important;
  min-width: 0;
  line-height: 36px;
  height: 36px;
  width: 100%;
  color: #5d5f6c;
  box-sizing: border-box;
}
.chatLeft .el-menu-item-group__title {
  display: none;
}

.el-submenu .el-menu-item:hover {
  background: rgba(0, 1, 8, 0.05);
}

.loading {
  width: 150px;
  height: 15px;
}
.loading span {
  display: inline-block;
  width: 7px;
  height: 7px;
  margin-right: 5px;
  border-radius: 50%;
  background: #777aed;
  -webkit-animation: load 1.04s ease infinite;
}
.loading span:last-child {
  margin-right: 0px;
}
/* 按钮置灰 */
button[disabled] {
  color: white !important;
  background-color: rgb(188, 188, 188) !important;
}
/* 按钮置灰 */
button[disabled]:hover {
  color: white !important;
  background-color: rgb(188, 188, 188) !important;
}
.StopResponding {
  width: 150px;
  background-color: #6567e1;
  position: absolute;
  right: 31%;
  bottom: 200px;
  color: #fff;
  line-height: 30px;
  border-radius: 3px;
  cursor: pointer;
}
.StopResponding img {
  width: 20px;
  margin-top: 5px;
  float: left;
  margin-left: 10px;
  margin-right: 3px;
}
@-webkit-keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.loading span:nth-child(1) {
  -webkit-animation-delay: 0.13s;
}
.loading span:nth-child(2) {
  -webkit-animation-delay: 0.26s;
}
.loading span:nth-child(3) {
  -webkit-animation-delay: 0.39s;
}
.loading span:nth-child(4) {
  -webkit-animation-delay: 0.52s;
}
.loading span:nth-child(5) {
  -webkit-animation-delay: 0.65s;
}
@media screen and (max-width: 990px) {
  .chatClass {
    width: 100%;
    height: auto;
    background: #f9f8fc;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    flex-direction: column;
    /* display: flex;
    align-content: flex-start; */
    padding: 20px 0px;
    margin: 40px auto 0;
    position: relative;
  }
  .chatRight_menu {
    width: auto;
    height: 400px;
    margin-right: 23px;
    margin-left: 38px;
    overflow-x: hidden;
    overflow-y: revert;
  }
  .chatRight_menu .el-menu-vertical-demo {
    width: 100%;
  }
  .el-menu {
    border-right: none;
    background: none;
    width: 100%;
    font-size: 16px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #333333;
    line-height: 16px;
  }
  .chatRight_gpt2 {
    width: 95%;
    height: 587px;
    /* background: #ffffff; */
    border-radius: 6px 6px 6px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    margin-left: 2.5%;
    /* margin: 0px auto 15px 0px; */
  }

  .chatRight_gpt2 span {
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    text-align: justify;
  }
  .chatRight_gpt {
    width: 100%;
    height: 248px;
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    padding: 15px 15px 23px 15px;
    margin-bottom: 15px;
    margin-top: 15px;
    /* margin-left: 2.5%; */
  }
  .chatRight_gpt:last-child {
    margin-bottom: 0;
    height: 163px;
    padding: 0px;
  }
  .chatRight_gpts {
    width: 100%;
    /* height: 255px; */
    background: #ffffff;
    border-radius: 6px 6px 6px 6px;
    padding: 15px 15px 23px 15px;
    /* margin-bottom: 15px; */
    display: flex;
  }
  .chatRight {
    padding: 20px;
  }
  .chatRight > p {
    margin-bottom: 10px;
  }
  .problemSpan {
    width: 366px;
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
    margin-left: 0px;
    margin-right: 20px;
    white-space: pre-wrap;
    background-color: #edebf3;
    border-radius: 4px;
    padding: 13px 15px;
    margin-top: 10px;
    /* overflow-y: scroll; */
    position: relative;
  }
  .ball {
    width: 55px;
    height: 50px;
    background: #6567e1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #fff;
    line-height: 14px;
    /* margin-left: 20px; */
    /* margin: 0px; */
  }
  .answer_span {
    width: 366px;
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
    margin-left: 15px;
    /* overflow-y: overlay; */
    white-space: pre-wrap;
    word-break: break-all;
    background-color: #f6f6f6;
    padding: 13px 15px;
    border-radius: 4px;
    position: relative;
  }
  .showGpt {
    height: 587px;
    background-color: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .dateTime {
    position: absolute;
    right: 0;
    top: -24px;
    font-size: 14px;
    color: #9e9e9e;
    width: 200px;
  }
  .dateTimeAns {
    position: absolute;
    left: 0;
    top: -24px;
    font-size: 14px;
    color: #9e9e9e;
    width: 200px;
  }
  .StopResponding {
    width: 200px;
    background-color: #6567e1;
    position: absolute;
    right: 30%;
    bottom: 200px;
    color: #fff;
    line-height: 30px;
    border-radius: 3px;
  }
  .StopResponding img {
    width: 20px;
    margin-top: 5px;
    float: left;
    margin-left: 10px;
    margin-right: 3px;
  }
}

.header-custom {
  img {
    position: absolute;
  }
  .phone {
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 49.1%;
  }
  .seo,
  .optimize,
  .common {
    height: 10%;
  }
  .seo {
    left: 13%;
    bottom: 33%;
  }
  .optimize {
    left: 22%;
    bottom: 16%;
  }
  .common {
    right: 18%;
    bottom: 5.5%;
  }
  .drainage {
    height: 14%;
    right: 13%;
    bottom: 35%;
  }
}
.similar-main,
.core-function,
.application-scene {
  width: 1280px;
  margin: auto;
}
.core-function {
  padding: 80px 0 63px;
}
.core-function /deep/ .common-two-column_img,
.application-scene /deep/ .common-two-column_img {
  width: 37.7%;
  margin-top: -23px;
  margin-right: 85px;
}
.core-function .common-two-column {
  column-gap: 85px;
}
.application-scene .common-two-column {
  column-gap: 109px;
}
.application-scene .common-two-column_img {
  margin-right: 61px;
}
.core-function_main,
.application-scene_main {
  margin-top: 80px;
}
.similar-main-wrap {
  padding: 80px 0;
  width: 100%;
  background: #f0f2f5;
}
.application-scene {
  padding-top: 80px;
  padding-bottom: 160px;
}
@media screen and (min-width: 1440px) {
  .header-custom {
    position: absolute;
    width: 1440px;
    height: 820px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
